var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "title": "Pilih Tempat Penyimpanan",
      "id": "pengembalian-modal",
      "size": "md"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    staticClass: "w-full",
    attrs: {
      "fields": _vm.fields,
      "items": _vm.penyimpanans
    },
    scopedSlots: _vm._u([{
      key: "cell(radio)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('b-form-radio', {
          attrs: {
            "name": "penyimpanan",
            "value": item
          },
          model: {
            value: _vm.selectedPenyimpanan,
            callback: function callback($$v) {
              _vm.selectedPenyimpanan = $$v;
            },
            expression: "selectedPenyimpanan"
          }
        })];
      }
    }, {
      key: "cell(blok)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.blok ? _c('span', [_vm._v(_vm._s(item.blok.blok))]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Blok tidak ditemukan")])];
      }
    }, {
      key: "cell(stok)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: item.jumlah,
            callback: function callback($$v) {
              _vm.$set(item, "jumlah", $$v);
            },
            expression: "item.jumlah"
          }
        })];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }